<script setup lang="ts">
import { BlendIcon } from 'lucide-vue-next';

const ANIMATION_DURATION = 2000;
const ANIMATION_START_DELAY = 500;

withDefaults(defineProps<{
  animateButton: boolean;
}>(), {  })

const buttonRef = ref<HTMLElement | null>(null);
const animate = ref(false);
const hasAnimated = ref(false);

const isVisible = __props.animateButton
  ? useElementVisibility(buttonRef)
  : ref(false);

if (__props.animateButton) {
  watch(isVisible, (visible) => {
    if (visible && !hasAnimated.value) {
      setTimeout(() => {
        hasAnimated.value = true;
        animate.value = true;
        setTimeout(() => {
          animate.value = false;
        }, ANIMATION_DURATION);
      }, ANIMATION_START_DELAY);
    }
  });
}
</script>

<template>
  <div
    ref="buttonRef"
    class="absolute bottom-[-10px] group/similar-button right-[4%] flex items-center overflow-hidden rounded-full bg-purple-light group-hover/tile:bottom-[110%] group-hover/similar-button:pr-4"
  >
    <div class="flex h-9 w-9 items-center justify-center">
      <BlendIcon
        class="size-4 text-purple"
        stroke-width="3"
      />
    </div>
    <span
      class="max-w-0 whitespace-nowrap text-dark text-xxs font-medium transition-all duration-300 group-hover/similar-button:max-w-[200px] group-hover/similar-button:pr-4"
      :class="[animate ? 'max-w-[200px] pr-4' : '']"
    >
      {{ $t('productDetail.similarDesigns.headline') }}
    </span>
  </div>
</template>
