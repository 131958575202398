<script setup lang="ts">
import { CREATE_YOUR_OWN_DESIGN_IDS } from 'localization';
import { LayoutGridIcon } from 'lucide-vue-next';
import { DesignType as DesignTypes } from '#gql/default';
import { SPLIT_EVENTS } from '@/config/splitio';
import type { DesignType } from '#gql/default';

const AMOUNT_TO_SHOW = 4;
const THUMBNAIL_IMAGES_MAX = 4;
const GALLERY_IMAGES_MAX = 5;
const RESPONSIVE_IMAGE_SIZES = '275px sm:300px ml:275px lg:300px xl:400px';

const {
  getActiveIndex,
  mainCarousel,
  onGoToSlide,
  setActiveIndex,
  thumbnailCarousel,
} = useCarousel();

withDefaults(defineProps<{
  altTag?: string;
  animateButton?: boolean;
  designType: DesignType;
  images: string[];
  imageSizes?: string;
  isMatureContent: boolean;
  isThumbnailVisible: boolean;
  objectId?: string;
  showSimilarDesigns?: boolean;
}>(), { animateButton: false,imageSizes: RESPONSIVE_IMAGE_SIZES,objectId: '0',showSimilarDesigns: false, })

const emit = defineEmits<{
  'similarDesignsClicked': [];
}>();

const { getIsMatureContentEnabled } = useMatureContent();
const { trackEvent } = useSplit();

const thumbnailImages = computed(() => __props.images.slice(0, THUMBNAIL_IMAGES_MAX));
const galleryImages = computed(() => __props.images.slice(0, GALLERY_IMAGES_MAX));

const visitedImageIndexes = new Set<number>();
watchEffect(() => visitedImageIndexes.add(getActiveIndex.value));

const moreToShow = computed(() => __props.images.length - AMOUNT_TO_SHOW > 0);

const shouldMatureContentBeBlurred = (imageIndex: number): boolean => !getIsMatureContentEnabled.value
  && __props.isMatureContent && imageIndex === getActiveIndex.value;

const showSimilarDesignsButton = computed(() => __props.showSimilarDesigns && !CREATE_YOUR_OWN_DESIGN_IDS.includes(__props.objectId));

const handleSimilarDesignButtonClick = () => {
  trackEvent(SPLIT_EVENTS.SIMILAR_DESIGN_CLICK, { objectId: __props.objectId });
  emit('similarDesignsClicked');
};
</script>

<template>
  <CarouselComponent
    ref="mainCarousel"
    class="product-tile-carousel overflow-hidden rounded-t-lg"
    :infinite="false"
    :nav-buttons="false"
    @before-change="setActiveIndex"
  >
    <div
      v-for="(item, itemIndex) in galleryImages"
      :key="itemIndex"
      class="product-tile-carousel__item flex aspect-328/297 h-full items-center justify-center"
      :data-cy="`product-tile-carousel-${itemIndex}`"
    >
      <UiMatureButton
        v-if="shouldMatureContentBeBlurred(itemIndex)"
        show-button
      />
      <NuxtResponsivePicture
        show-loading-skeleton
        class="w-full"
        provider="cloudfront"
        :alt="altTag"
        :class="[shouldMatureContentBeBlurred(itemIndex) ? 'blur-xl' : 'blur-none']"
        :sizes="imageSizes"
        :src="visitedImageIndexes.has(itemIndex) ? item : undefined"
      />
    </div>
  </CarouselComponent>
  <div class="carousel-container absolute w-full -translate-y-1/2">
    <CarouselComponent
      v-if="isThumbnailVisible"
      ref="thumbnailCarousel"
      class="thumbnail-carousel mx-1.5 hidden rounded-sm bg-grey-mid/60 bg-opacity-[94%] p-2.5 pb-4 backdrop-blur-md group-hover/tile:block"
      :dots="false"
      :infinite="false"
      :nav-buttons="false"
      :use-as-navigation-for="mainCarousel ? [mainCarousel] : []"
    >
      <div
        v-for="(item, thumbIndex) in thumbnailImages"
        :key="thumbIndex"
        class="thumbnail-carousel__item-wrapper flex aspect-5/4 items-center justify-center overflow-hidden rounded-sm bg-white hover:cursor-pointer"
        @mouseenter="onGoToSlide(thumbIndex)"
        @touchend="onGoToSlide(thumbIndex)"
      >
        <UiMatureButton
          v-if="!getIsMatureContentEnabled && isMatureContent"
          icon-small
        />
        <NuxtResponsivePicture
          show-loading-skeleton
          class="thumbnail-carousel__image block aspect-5/4 size-full rounded-sm opacity-50"
          provider="cloudfront"
          :alt="altTag"
          :class="[
            { 'solid border border-dark opacity-100!': getActiveIndex === thumbIndex },
            !getIsMatureContentEnabled && isMatureContent ? 'blur-md' : 'blur-none'
          ]"
          :src="item"
          :width="60"
        />
      </div>
      <div
        v-if="moreToShow"
        class="thumbnail-carousel__item-wrapper thumbnail more-to-show flex aspect-5/4 h-full items-center justify-center overflow-hidden rounded-sm bg-white"
      >
        <LayoutGridIcon class="size-5 text-grey" />
      </div>
    </CarouselComponent>
    <SimilarDesignsButton
      v-if="showSimilarDesignsButton"
      data-fs="design-tile-similar-designs-button"
      :animate-button="animateButton"
      @click.prevent="handleSimilarDesignButtonClick"
      @touchstart.prevent="handleSimilarDesignButtonClick"
    />
    <DesignTypeBadge
      v-if="designType === DesignTypes.CREATE && !showSimilarDesigns"
      class="absolute bottom-0 right-4 translate-y-1/2 hover:cursor-pointer"
      :type="designType"
    />
  </div>
</template>

<style scoped>
@reference '@/styles/global.css';

.product-tile-carousel :deep(.carousel-component__list) {
  @apply h-full aspect-327/297;
}

.thumbnail-carousel :deep(.carousel-component__track),
.thumbnail-carousel :deep(.carousel-component__slides) {
  @apply w-full;
}

.thumbnail-carousel :deep(.carousel-component__slides) {
  @apply grid grid-cols-5 gap-x-2.5;
}

.thumbnail-carousel :deep(.carousel-component__list) {
  @apply overflow-visible;
}
</style>
